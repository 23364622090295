<template>
  <div
    class="product-create create-form"
    v-if="getPermission('product:create')"
  >
    <v-form
      ref="productForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="onSubmit"
    >
      <v-row>
        <v-col cols="12" class="pt-0">
          <v-container fluid class="white-background main-create-page">
            <div class="p-5 pb-0">
              <v-row>
                <v-col cols="6" class="pt-0">
                  <h1 class="form-title d-flex">
                    <template v-if="productId">Update</template>
                    <template v-else>Create</template> Competitors Asset
                    <span class="orange--text ml-2"
                      >#{{ productCreate?.barcode }}</span
                    >
                  </h1>
                </v-col>
                <v-col cols="6" class="pt-0 text-right">
                  <v-btn
                    depressed
                    :disabled="!formValid || formLoading"
                    :loading="formLoading"
                    class="mx-2 custom-bold-button text-white"
                    color="cyan"
                    v-on:click="onSubmit"
                  >
                    <template v-if="productId">Update</template>
                    <template v-else>Save</template> Competitors Asset
                  </v-btn>
                  <v-btn
                    depressed
                    v-on:click="goBack"
                    :disabled="formLoading"
                    class="mx-2 custom-grey-border custom-bold-button"
                    outlined
                    >Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <ProductCreate
              :productId="productId"
              :barcodeData="barcode"
              :updateMode="updateMode"
              :productCreateData="productCreate"
              v-on:saveProduct="updateProduct"
            ></ProductCreate>
          </v-container>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProductMixin from "@/core/plugins/product-mixin";
import { ProductBrandEventBus } from "@/core/lib/product.brand.lib";

import {
  POST,
  GET,
  PUT,
  CLEAR_ERROR,
  UPDATE_ERROR,
} from "@/core/services/store/request.module";
import ProductCreate from "@/view/pages/product/partials/Create.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
//import { map } from "lodash";
export default {
  mixins: [CommonMixin, ProductMixin],
  name: "product-create",
  title: "Create Product",
  data() {
    return {
      barcode: null,
      barcodeDialog: false,
      updateMode: false,
      barcodeSetting: {
        prefix_string: null,
        next_number: null,
        number_length: null,
      },
      productCreate: null,
      productId: null,
      duplicateId: null,
    };
  },
  components: {
    ProductCreate,
  },
  beforeUpdate() {
    this.$store.dispatch(CLEAR_ERROR);
  },
  methods: {
    updateProduct(param) {
      this.productCreate = param;
    },
    getProduct(productId) {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (productId && productId > 0) {
          _this.$store
            .dispatch(GET, { url: "product/" + productId })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          resolve({});
        }
      });
    },
    createWarrantyEntity() {
      const _this = this;
      return new Promise((resolve) => {
        let warrantyFormData = _this.lodash.compact(
          _this.lodash.map(_this.productCreate.warranty, function (param) {
            if (param.field && param.value) {
              return param;
            }
          })
        );

        if (_this.lodash.isEmpty(warrantyFormData) === false) {
          _this.formLoading = true;
          _this.$store
            .dispatch(POST, {
              url: "product/" + _this.productId + "/entity",
              data: {
                type: 1,
                entities: warrantyFormData,
              },
            })
            .then(() => {
              resolve(true);
            })
            .catch((error) => {
              _this.logError(error);
            })
            .finally(() => {
              _this.formLoading = false;
            });
        } else {
          resolve(true);
        }
      });
    },
    onSubmit() {
      const _this = this;
      if (!_this.$refs.productForm.validate()) {
        return false;
      }
      if (
        Number(this.productCreate.selling_cost) <
        Number(this.productCreate.threshold_price)
      ) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError(
            "Selling cost / Unit cost Should be greater than Threshold Price."
          )
        );
        return false;
      }
      _this.formLoading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let requestType = POST;
      let requestURL = "product";

      if (_this.productId && _this.productId > 0) {
        requestType = PUT;
        requestURL = "product/" + _this.productId;
      }
      _this.productCreate.custom_fields = _this.gCreateFormFields;
      // console.log(_this.productCreate);
      _this.$store
        .dispatch(requestType, { url: requestURL, data: _this.productCreate })
        .then(async (response) => {
          _this.productId = response.data ? response.data.id : null;

          await _this.createWarrantyEntity();

          _this.$router.push(
            _this.getDefaultRoute("product", {
              query: {
                status: "all",
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    loadContent() {
      const _this = this;
      _this
        .getProduct(_this.productId)
        .then((response) => {
          if (_this.lodash.isEmpty(response) === false) {
            _this.$store.dispatch(SET_BREADCRUMB, [
              { title: "Competitors Asset", route: "product" },
              { title: "Update" },
            ]);

            _this.productCreate = {
              name: response.name,
              /*    quantity: response.quantity, */
              reference: response.reference,
              sku: response.sku,
              upc: response.upc,
              ean: response.ean,
              isbn: response.isbn,
              images: response.images,
              product_images: response.product_images,
              product_attachments: response.product_attachments,
              part_number: response.part_number,
              hsn_code: response.hsn_code,
              serial_number: response.serial_number,
              ref_number: response.ref_number,
              item_number: response.item_number,
              description: response.description,
              directory: response.directory,
              supplier: response.supplier ? response.supplier.id : null,
              brand: response.brand ? response.brand : null,
              barcode: response.barcode ? response.barcode : null,
              manufacturer: response.manufacturer
                ? response.manufacturer
                : null,
              category: response.category ? response.category : null,
              unit: response.unit ? response.unit : null,
              tax: response.tax ? response.tax.id : null,
              tax_exemption: response.tax_exemption
                ? response.tax_exemption.id
                : null,
              accounting: response.accounting,
              product_type: response.product_type,
              purchase_cost: response.purchase_cost,
              selling_cost: response.selling_cost,

              project_price: response.project_price,
              threshold_price: response.threshold_price,
              commission_rate: response.commission_rate,
              website_url: response.website_url,

              length: response.length,
              width: response.width,
              height: response.height,
              weight: response.weight,
              taxable: response.is_taxable,
              sellable: response.is_sellable,
              confirmed: response.is_confirmed,
              track_batch: response.track_batch,
              manage_stock: response.manage_stock,
              reorder_level: response.reorder_level,
              initial_stock: response.initial_stock,
              charges: response.charges,
              warranty_type: response.duration_time,
              warranty_duration: response.warranty_check,
              purchase_date: response.purchase_date
                ? response.purchase_date
                : null,

              model: response.model ? response.model : null,
              model_no: response.model_no ? response.model_no : null,

              model_type: response.model_type,
              seller: response.seller,
              mast_height: response.mast_height,
              tires: response.tires,
              att: response.att,
              engine: response.engine,
              power_source: response.power_source,
              battery_voltage: response.battery_voltage
                ? response.battery_voltage
                : null,

              battery_capacity: response.battery_capacity
                ? response.battery_capacity
                : null,
              factory: response.factory ? response.factory : null,
              delivery_terms: response.delivery_terms,
              warranty_truck: response.warranty_truck
                ? response.warranty_truck
                : null,

              warranty_li_ion_battery: response.warranty_li_ion_battery
                ? response.warranty_li_ion_battery
                : null,
              country_quote: response.country_quote
                ? response.country_quote
                : null,

              length_to_fork: response.length_to_fork
                ? response.length_to_fork
                : null,
              overall_width: response.overall_width
                ? response.overall_width
                : null,
              turning_radius: response.turning_radius
                ? response.turning_radius
                : null,
              ground_clearance: response.ground_clearance
                ? response.ground_clearance
                : null,
              travel_speed: response.travel_speed
                ? response.travel_speed
                : null,
              lift_speed: response.lift_speed ? response.lift_speed : null,
              max_grade: response.max_grade ? response.max_grade : null,
              drive_medium: response.drive_medium
                ? response.drive_medium
                : null,
              hydraulic: response.hydraulic ? response.hydraulic : null,
              power: response.power ? response.power : null,
              torque: response.torque ? response.torque : null,
              noise_level: response.noise_level ? response.noise_level : null,
              entry_date: response.entry_date ? response.entry_date : null,

              local_currency: response.local_currency
                ? response.local_currency
                : null,
              local_selling_price: response.local_selling_price
                ? response.local_selling_price
                : null,
              local_selling_price_fx: response.local_selling_price_fx
                ? response.local_selling_price_fx
                : null,
              local_selling_price_jpy: response.local_selling_price_jpy
                ? response.local_selling_price_jpy
                : null,
              import_dn_currency: response.import_dn_currency
                ? response.import_dn_currency
                : null,
              import_dn_price: response.import_dn_price
                ? response.import_dn_price
                : null,
              import_dn_price_fx: response.import_dn_price_fx
                ? response.import_dn_price_fx
                : null,
              import_dn_price_jpy: response.import_dn_price_jpy
                ? response.import_dn_price_jpy
                : null,

              /* warranty: _this.lodash.compact(
                _this.lodash.map(response.warranty_data, function (param) {
                  if (param.field && param.value) {
                    return {
                      id: param.id,
                      field: param.field,
                      value: param.value,
                    };
                  }
                })
              ), */

              priceList: _this.lodash.compact(
                _this.lodash.map(response.product_pricelogs, function (row) {
                  return {
                    id: row.id ? row.id : null,
                    unit_price: row.unit_price ? row.unit_price : 0,
                    selling_cost: row.sale_price ? row.sale_price : 0,
                    discount_price: row.discount_price ? row.discount_price : 0,
                    start_date: row.start_date,
                    end_date: row.end_date,
                  };
                })
              ),
              suppliers: _this.lodash.compact(
                _this.lodash.map(response.product_supplier, function (row) {
                  return {
                    id: row.id,
                    supplier_id: row.supplier,
                    part_number: row.part_number,
                    supplier_price: row.supplier_price,
                  };
                })
              ),
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
    loadDuplicateContent() {
      const _this = this;
      _this
        .getProduct(_this.duplicateId)
        .then((response) => {
          /*   console.log(response.warranty_data.length)
            if(response && response.warranty_data && response.warranty_data.length > 0){
               this.warrantyActive = true;
            } */
          if (_this.lodash.isEmpty(response) === false) {
            _this.productCreate = {
              barcode: this.barcode,
              name: response.name,
              /*  quantity: response.quantity, */
              reference: response.reference,
              ref_number: response.ref_number,
              item_number: response.item_number,
              sku: response.sku,
              upc: response.upc,
              ean: response.ean,
              isbn: response.isbn,
              images: response.images,
              product_images: [], //response.product_images,
              part_number: response.part_number,
              hsn_code: response.hsn_code,
              description: response.description,
              project_price: response.project_price,
              serial_number: response.serial_number,
              threshold_price: response.threshold_price,
              directory: response.directory,
              supplier: response.supplier ? response.supplier.id : null,
              brand: response.brand ? response.brand : null,
              manufacturer: response.manufacturer
                ? response.manufacturer
                : null,
              category: response.category ? response.category : null,
              unit: response.unit ? response.unit : null,
              tax: response.tax ? response.tax.id : null,
              tax_exemption: response.tax_exemption
                ? response.tax_exemption.id
                : null,
              accounting: response.accounting,
              product_type: response.product_type,
              purchase_cost: response.purchase_cost,
              selling_cost: response.selling_cost,
              length: response.length,
              width: response.width,
              height: response.height,
              weight: response.weight,
              taxable: response.is_taxable,
              sellable: response.is_sellable,
              confirmed: response.is_confirmed,
              initial_stock: response.initial_stock,
              track_batch: response.track_batch,
              manage_stock: response.manage_stock,
              reorder_level: response.reorder_level,
              charges: response.charges,
              website_url: response.website_url,
              model: response.model ? response.model : null,
              model_no: response.model_no ? response.model_no : null,
              warranty_type: response.duration_time,
              warranty_duration: response.warranty_check,
              purchase_date: response.purchase_date
                ? response.purchase_date
                : null,

              model_type: response.model_type,
              seller: response.seller,
              mast_height: response.mast_height,
              tires: response.tires,
              att: response.att,
              engine: response.engine,
              power_source: response.power_source,
              battery_voltage: response.battery_voltage
                ? response.battery_voltage
                : null,

              battery_capacity: response.battery_capacity
                ? response.battery_capacity
                : null,
              factory: response.factory ? response.factory : null,
              delivery_terms: response.delivery_terms,
              warranty_truck: response.warranty_truck
                ? response.warranty_truck
                : null,

              warranty_li_ion_battery: response.warranty_li_ion_battery
                ? response.warranty_li_ion_battery
                : null,
              country_quote: response.country_quote
                ? response.country_quote
                : null,

              length_to_fork: response.length_to_fork
                ? response.length_to_fork
                : null,
              overall_width: response.overall_width
                ? response.overall_width
                : null,
              turning_radius: response.turning_radius
                ? response.turning_radius
                : null,
              ground_clearance: response.ground_clearance
                ? response.ground_clearance
                : null,
              travel_speed: response.travel_speed
                ? response.travel_speed
                : null,
              lift_speed: response.lift_speed ? response.lift_speed : null,
              max_grade: response.max_grade ? response.max_grade : null,
              drive_medium: response.drive_medium
                ? response.drive_medium
                : null,
              hydraulic: response.hydraulic ? response.hydraulic : null,
              power: response.power ? response.power : null,
              torque: response.torque ? response.torque : null,
              noise_level: response.noise_level ? response.noise_level : null,
              entry_date: response.entry_date ? response.entry_date : null,

              local_currency: response.local_currency
                ? response.local_currency
                : null,
              local_selling_price: response.local_selling_price
                ? response.local_selling_price
                : null,
              local_selling_price_fx: response.local_selling_price_fx
                ? response.local_selling_price_fx
                : null,
              local_selling_price_jpy: response.local_selling_price_jpy
                ? response.local_selling_price_jpy
                : null,
              import_dn_currency: response.import_dn_currency
                ? response.import_dn_currency
                : null,
              import_dn_price: response.import_dn_price
                ? response.import_dn_price
                : null,
              import_dn_price_fx: response.import_dn_price_fx
                ? response.import_dn_price_fx
                : null,
              import_dn_price_jpy: response.import_dn_price_jpy
                ? response.import_dn_price_jpy
                : null,

              /* warranty: _this.lodash.compact(
                _this.lodash.map(response.warranty_data, function (param) {
                  if (param.field && param.value) {
                    return {
                      id: null,
                      field: param.field,
                      value: param.value,
                    };
                  }
                })
              ), */
              priceList: _this.lodash.compact(
                _this.lodash.map(response.product_pricelogs, function (row) {
                  return {
                    id: row.id ? row.id : null,
                    unit_price: row.unit_price ? row.unit_price : 0,
                    selling_cost: row.sale_price ? row.sale_price : 0,
                    discount_price: row.discount_price ? row.discount_price : 0,
                    start_date: row.start_date,
                    end_date: row.end_date,
                  };
                })
              ),
              suppliers: _this.lodash.compact(
                _this.lodash.map(response.product_supplier, function (row) {
                  return {
                    id: row.id,
                    supplier_id: row.supplier,
                    part_number: row.part_number,
                    supplier_price: row.supplier_price,
                  };
                })
              ),
            };
          }
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
  },
  created() {
    const _this = this;
    _this.productId = _this.lodash.toSafeInteger(_this.$route.params.id);
    _this.duplicateId = _this.lodash.toSafeInteger(
      _this.$route.query.duplicate
    );
  },
  computed: {
    ...mapGetters(["gCreateFormFields"]),
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Competitors Asset", route: "product" },
      { title: "Create" },
    ]);

    if (
      _this.$route.name == "admin.product.update" ||
      _this.$route.name == "engineer.product.update"
    ) {
      _this.updateMode = true;

      if (_this.productId <= 0) {
        _this.$router.go(-1);
      }

      _this.loadContent();
    }

    if (_this.duplicateId > 0) {
      _this.loadDuplicateContent();
    }

    ProductBrandEventBus.$on("refress-brand", function () {
      this.getProduct(_this.productId);
    });
  },
};
</script>
